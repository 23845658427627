/* eslint-disable */
<template>
  <div class="card social-card-h">
    <!-- <div class="card-header border-0"> -->
    <!-- <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">
            {{ $t("social_traffic_table.test_generation_highlights") }}
          </h3>
        </div>
      </div>
    </div> -->
    <div class="table-responsive" v-if="tghData.length > 0">
      <el-table
        :emptyText="$t('social_traffic_table.empty')"
        class="table-responsive table"
        header-row-class-name="thead-primary"
        :data="formatRow()"
        height="800"
      >
        <!-- @row-click="onRowClicked" -->
        <el-table-column
          class="el-table-custom"
          label="Tests"
          min-width="10"
          prop="name"
        >
          <template v-slot="{ row }">
            <el-tooltip :content="row.name">
              <div class="font-weight-600 elipsis--text" v-if="row">
                {{ row.name }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column
          class="text-center"
          label="Language"
          min-width="6"
          prop="step_count"
        >
          <template v-slot="{ row }">
            <div
              class="font-weight-600 elipsis--text"
              style="display: flex; justify-content: center"
              v-if="row"
            >
              <!-- <span v-if="row.status == 'passed'" class="status-span passed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M16.8 8.40039L9.64043 15.6004L7.19995 13.1461" stroke="#E6E6E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
<span v-else class="status-span failed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M16 8L8 16M16 16L8 8" stroke="#E6E6E6" stroke-width="2" stroke-linecap="round"/>
</svg></span> -->

              <el-tooltip
                :content="
                  row.output_type === 'cucumber'
                    ? 'Cucumber File'
                    : row.output_type === 'sensetalk'
                    ? 'Sensetalk File'
                    : row.output_type === 'wringr'
                    ? 'Wringr File'
                    : row.output_type === 'cypress'
                    ? 'Cypress File'
                    : row.output_type === 'selenium'
                    ? 'Selenium File'
                    : row.output_type === 'playwright'
                    ? 'Playwright File'
                    : row.output_type === 'puppeteer'
                    ? 'Puppeteer File'
                    : row.output_type === 'cucumberconfig'
                    ? 'Cucumber Config File'
                    : row.output_type === 'sensetalk_zip'
                    ? 'Sensetalk Archive File'
                    : row.output_type === 'cucumber_natural'
                    ? 'Cucumber File'
                    : ''
                "
                placement="top"
              >
                <img
                  class="icon-test"
                  v-if="row.output_type === 'sensetalk'"
                  src="./../../assets/images/eggplant_logo.svg"
                  alt="Sensetalk"
                />
                <img
                  class="icon-test"
                  v-else-if="row.output_type === 'wringr'"
                  src="./../../assets/images/favicon.png"
                  alt="Wringr"
                />
                <img
                  class="icon-test"
                  v-else-if="row.output_type === 'cypress'"
                  src="./../../assets/images/cypress.svg"
                  alt="Cypress"
                />
                <img
                  class="icon-test"
                  v-else-if="row.output_type === 'selenium'"
                  src="./../../assets/images/selenium.png"
                  alt="Selenium"
                />
                <img
                  class="icon-test"
                  v-else-if="row.output_type === 'playwright'"
                  src="./../../assets/images/playwright.svg"
                  alt="Playwright"
                />
                <img
                  class="icon-test"
                  v-else-if="row.output_type === 'puppeteer'"
                  src="./../../assets/images/pptr.svg"
                  alt="Puppeteer"
                />
                <img
                  class="icon-test"
                  v-else-if="row.output_type === 'cucumber'"
                  src="./../../assets/images/Cucumber.svg"
                  alt="Cucumber"
                />
                <img
                  class="icon-test"
                  v-else-if="row.output_type === 'cucumberconfig'"
                  src="./../../assets/images/config.svg"
                  alt="Cucumber Config"
                />
                <img
                  class="icon-test"
                  v-else-if="row.output_type === 'sensetalk_zip'"
                  src="./../../assets/images/SensetalkSuite.png"
                  alt="Sensetalk Suite"
                />
                <img
                  class="icon-test"
                  v-else-if="row.output_type === 'preview'"
                  src="./../../assets/images/Cucumber.svg"
                  alt="Cucumber"
                />
                <img
                  class="icon-test"
                  v-else-if="row.output_type === 'cucumber_natural'"
                  src="./../../assets/images/Cucumber.svg"
                  alt="Cucumber"
                />
                <img class="icon-test" v-else src="N/A" alt="Unknown Type" />
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    <div v-else>-->
    <!--      <img-->
    <!--        src="/img/loading.gif"-->
    <!--        alt="loading..."-->
    <!--        style="height: 100px; width: 100px"-->
    <!--      />-->
    <!--    </div>-->
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui";
export default {
  name: "social-traffic-table",
  props: {
    tghData: Array,
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  data() {
    return {
      log: console.log,
      toShow: [],
      altLabel: "",
      srcIcon: "",
    };
  },
  created() {
    this.toShow = [...this.tghData].filter((elt, id) => id < 7);
  },
  methods: {
    formatRow() {
      let a = this.tghData;
      let b = [];
      for (var i = 0; i < a.length; i++) {
        b.push(a[i]);
      }
      return b;
    },

    onRowClicked(item, index, event) {
      const element = event.srcElement.id;
      if (element === "") {
        if (item.testSuiteId) {
          this.$router.push({
            name: "Suite View Item",
            params: {
              suiteId: item.testSuiteId,
              id: item.requestId,
            },
          });
        } else {
          this.$router.push({
            name: "View Item",
            params: {
              id: item.request_id,
            },
          });
        }
      }
    },
  },
};
</script>
<style>
/* .social-card-h {
height: 100%; 
} */

.social-card-h .table-responsive {
  overflow: hidden !important;
}

.table-responsive .el-table__row {
  background: orange;
}

.table-responsive .el-table__body-wrapper .el-table__body .el-table_1_column_1 {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}

.table-responsive
  .el-table__body-wrapper
  .el-table__body
  .el-table_1_column_1
  .cell {
  line-height: 1.5;
  padding: 0.5rem !important;
  margin: 0 !important;
}

.elipsis--text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.status-span {
  border-radius: 10px;
  padding: 8px;
}
.failed {
  background-color: #d86f6f;
}
.passed {
  background-color: #3a9679;
}

.icon-test {
  width: 30px;
}
</style>
