var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card social-card-h"},[(_vm.tghData.length > 0)?_c('div',{staticClass:"table-responsive"},[_c('el-table',{staticClass:"table-responsive table",attrs:{"emptyText":_vm.$t('social_traffic_table.empty'),"header-row-class-name":"thead-primary","data":_vm.formatRow(),"height":"800"}},[_c('el-table-column',{staticClass:"el-table-custom",attrs:{"label":"Tests","min-width":"10","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-tooltip',{attrs:{"content":row.name}},[(row)?_c('div',{staticClass:"font-weight-600 elipsis--text"},[_vm._v(" "+_vm._s(row.name)+" ")]):_vm._e()])]}}],null,false,1388969030)}),_c('el-table-column',{staticClass:"text-center",attrs:{"label":"Language","min-width":"6","prop":"step_count"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row)?_c('div',{staticClass:"font-weight-600 elipsis--text",staticStyle:{"display":"flex","justify-content":"center"}},[_c('el-tooltip',{attrs:{"content":row.output_type === 'cucumber'
                    ? 'Cucumber File'
                    : row.output_type === 'sensetalk'
                    ? 'Sensetalk File'
                    : row.output_type === 'wringr'
                    ? 'Wringr File'
                    : row.output_type === 'cypress'
                    ? 'Cypress File'
                    : row.output_type === 'selenium'
                    ? 'Selenium File'
                    : row.output_type === 'playwright'
                    ? 'Playwright File'
                    : row.output_type === 'puppeteer'
                    ? 'Puppeteer File'
                    : row.output_type === 'cucumberconfig'
                    ? 'Cucumber Config File'
                    : row.output_type === 'sensetalk_zip'
                    ? 'Sensetalk Archive File'
                    : row.output_type === 'cucumber_natural'
                    ? 'Cucumber File'
                    : '',"placement":"top"}},[(row.output_type === 'sensetalk')?_c('img',{staticClass:"icon-test",attrs:{"src":require("./../../assets/images/eggplant_logo.svg"),"alt":"Sensetalk"}}):(row.output_type === 'wringr')?_c('img',{staticClass:"icon-test",attrs:{"src":require("./../../assets/images/favicon.png"),"alt":"Wringr"}}):(row.output_type === 'cypress')?_c('img',{staticClass:"icon-test",attrs:{"src":require("./../../assets/images/cypress.svg"),"alt":"Cypress"}}):(row.output_type === 'selenium')?_c('img',{staticClass:"icon-test",attrs:{"src":require("./../../assets/images/selenium.png"),"alt":"Selenium"}}):(row.output_type === 'playwright')?_c('img',{staticClass:"icon-test",attrs:{"src":require("./../../assets/images/playwright.svg"),"alt":"Playwright"}}):(row.output_type === 'puppeteer')?_c('img',{staticClass:"icon-test",attrs:{"src":require("./../../assets/images/pptr.svg"),"alt":"Puppeteer"}}):(row.output_type === 'cucumber')?_c('img',{staticClass:"icon-test",attrs:{"src":require("./../../assets/images/Cucumber.svg"),"alt":"Cucumber"}}):(row.output_type === 'cucumberconfig')?_c('img',{staticClass:"icon-test",attrs:{"src":require("./../../assets/images/config.svg"),"alt":"Cucumber Config"}}):(row.output_type === 'sensetalk_zip')?_c('img',{staticClass:"icon-test",attrs:{"src":require("./../../assets/images/SensetalkSuite.png"),"alt":"Sensetalk Suite"}}):(row.output_type === 'preview')?_c('img',{staticClass:"icon-test",attrs:{"src":require("./../../assets/images/Cucumber.svg"),"alt":"Cucumber"}}):(row.output_type === 'cucumber_natural')?_c('img',{staticClass:"icon-test",attrs:{"src":require("./../../assets/images/Cucumber.svg"),"alt":"Cucumber"}}):_c('img',{staticClass:"icon-test",attrs:{"src":"N/A","alt":"Unknown Type"}})])],1):_vm._e()]}}],null,false,3842714711)})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }