<template>
  <div class="right-content" style="overflow: hidden">
    <div
      style="flex-direction: column; align-items: flex-start"
      class="page_name_section"
    >
      <div class="c--breadcrumb my-2">
        <router-link to="/">
          <svg
            style="margin-right: 5px"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="home icon">
              <path
                id="Icon"
                d="M9 21V14.1528C9 13.5226 9.53726 13.0116 10.2 13.0116H13.8C14.4627 13.0116 15 13.5226 15 14.1528V21M11.3046 3.21117L3.50457 8.48603C3.18802 8.7001 3 9.04665 3 9.41605V19.2882C3 20.2336 3.80589 21 4.8 21H19.2C20.1941 21 21 20.2336 21 19.2882V9.41605C21 9.04665 20.812 8.70011 20.4954 8.48603L12.6954 3.21117C12.2791 2.92961 11.7209 2.92961 11.3046 3.21117Z"
                stroke="#8F8F8F"
                stroke-width="2"
                stroke-linecap="round"
              />
            </g>
          </svg>
        </router-link>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="chevron-double-left icon">
            <path
              id="Icon"
              d="M13.4 5.80018L19 11.4002L13.4 17.0002M5 5.80018L10.6 11.4002L5 17.0002"
              stroke="#8F8F8F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
        <span class="text-near-arrow"> Dashboard</span>
      </div>
      <h2 class="page_name">Dashboard</h2>
      <!-- <i class="home-custom"></i> -->
    </div>
    <div class="container-mainstats">
      <div style="width: 50%">
        <div style="height: 100%">
          <h3 class="title-dashboard">Recent (last 10)</h3>
          <social-traffic-table
            v-if="testGenerationTableData.length > 0"
            style="max-width: 600px"
            :class="{ 'bg-dark': isDarkMode }"
            :tghData="testGenerationTableData"
          ></social-traffic-table>
          <div v-else class="container-nodata-test">
            <img
              v-if="loadingTests"
              src="img/loading.gif"
              width="48"
              height="48"
            />
            <h3 v-else style="text-align: center">No Data</h3>
          </div>
        </div>
      </div>
      <!--Charts-->

      <div class="diagrams-container">
        <!-- <base-header :class="isDarkMode ? `mb-3 bg-dark` : `mb-3 bg-gradient-info`"
          style=" background-color: #E6E6E6 !important;max-height: 250px;display: flex;align-items: center;"> -->
        <h3
          class="title-dashboard"
          style="margin-bottom: 0px; height: 10px"
        ></h3>
        <div class="stats-grid">
          <div class="card-stats">
            <stats-card
              class="custom-cards-dash"
              type="gradient-darkgrey"
              :title="$t('dashboard.user_sessions')"
              :sub-title="totalSession.toString()"
            >
              <!--                 tests_by_app: "Test Runs By App",-->
              <!--    events_last_week: "New Events Generated Last Week",-->
              <!--    key_flows_last_week: "Key Flows Generated",-->
              <!--    bugs_discovered: "Bugs Discovered",-->
              <!--    bug_finder_runs: "Bug Finder Runs",-->
              <!--    latest_session_video: "Latest Session Video",-->
              <!--    latest_key_flow: "Latest Key Flow",-->

              <!-- <template slot="footer">
                  <p class="mt-3 mb-0 text-sm"> -->
              <!--                <span class="text-success mr-2"-->
              <!--                  ><i class="fa fa-arrow-up"></i> 3.48%</span-->
              <!--                >-->
              <!-- <span class="text-nowrap">{{ $t('dashboard.since_last_week') }}</span>
                  </p>
                </template> -->
            </stats-card>
            <img
              style="position: absolute; z-index: -1; display: none"
              src="/img/loading.gif"
              alt="loading..."
            />
          </div>
          <div class="card-stats">
            <stats-card
              class="custom-cards-dash"
              :title="$t('dashboard.tests_gen')"
              type="gradient-darkgrey"
              :sub-title="toShowKeyFlows.toString()"
            >
              <!-- <template slot="footer">
                  <p class="mt-3 mb-0 text-sm"> -->
              <!--                <span class="text-success mr-2"-->
              <!--                  ><i class="fa fa-arrow-up"></i> 12.18%</span-->
              <!--                >-->
              <!-- <span class="text-nowrap">{{ $t('dashboard.since_last_week') }}</span>
                  </p>
                </template> -->
            </stats-card>
          </div>
          <div class="card-stats">
            <stats-card
              class="custom-cards-dash"
              v-loading="loading"
              :title="$t('dashboard.tests_session')"
              type="gradient-darkgrey"
              :sub-title="
                downloaded_tests && downloaded_tests.avg_tests_per_session
                  ? Math.ceil(downloaded_tests.avg_tests_per_session).toString()
                  : 'N/A'
              "
            >
            </stats-card>
          </div>
          <div class="card-stats">
            <stats-card
              class="custom-cards-dash"
              v-loading="loading"
              :title="$t('dashboard.tests_user')"
              type="gradient-darkgrey"
              :sub-title="
                downloaded_tests && downloaded_tests.event_count
                  ? Math.ceil(downloaded_tests.event_count).toString()
                  : 'N/A'
              "
            >
            </stats-card>
          </div>
        </div>
        <div class="custom-chart-gauge-pie">
          <h3 class="title-dashboard" style="padding-left: 75px">
            Generated Tests
          </h3>
          <div class="custom-chart">
            <v-chart
              class="chart"
              :autoresize="true"
              :option="overviewPieChart"
            />
          </div>
        </div>

        <!-- <div class="custom-chart-gauge">
            <h3 class="title-dashboard" style="padding-left: 70px;">Avg Time To Generate Test</h3>
            <v-chart class="chart" :autoresize="true" :option="gaugeData" />
          </div> -->
      </div>
    </div>

    <div
      :class="
        isDarkMode
          ? `container-fluid bg-gradient-darker`
          : `container-fluid bg-gradient-secondary`
      "
      style="padding: 0"
    >
      <div class="row">
        <div v-if="toShowVideo" class="col-xl-5 r-3-cards">
          <!-- <div style="cursor: pointer;" @click="а(resultSession[0])"> -->
          <!-- <stats-card   class="only-body" :title="$t('dashboard.latest_session_video')">
                <el-button style="margin-left: 10px;" id="show_det_btn" class="mb-2 custom-btn--details" @click="showDetailsSession()">
                  Details
                </el-button>
                <div v-loading="loadingSession" id="rrweb"></div>
              </stats-card> -->
          <!-- </div> -->
          <!-- <stats-card v-loading="loadingAppMap" class="only-body" :title="$t('dashboard.latest_key_flow')"
              type="gradient-orange" :sub-title="toShowLatestKeyFlow
                ? `Afchess:` + ` ` + toShowAllKeyFlows[toShowLatestKeyFlow]?.label.toString().toUpperCase().replace(/[\.]/g, '')
                : '0'
                " icon="fas fa-hourglass-end">
            </stats-card> -->
          <!--          <stats-card-->
          <!--            v-loading="loading"-->
          <!--            class="only-body"-->
          <!--            :title="$t('dashboard.test_case_healed')"-->
          <!--            type="gradient-red"-->
          <!--            :sub-title="-->
          <!--              +parseFloat(dashboardData.test_improvements * 100).toFixed(2) +-->
          <!--                '%'-->
          <!--            "-->
          <!--            icon="fas fa-chart-pie"-->
          <!--          >-->
          <!--          </stats-card>-->
        </div>
        <div
          class="col-xl-7"
          :style="{ flex: toShowVideo ? '1 0 0' : 'none', maxWidth: '100%' }"
          style="width: 100%"
        >
          <div>
            <!--            <v-chart-->
            <!--                title="$t('dashboard.average_runtime')"-->

            <!--                class="chart"-->
            <!--              :autoresize="true"-->
            <!--              :loading="chartLoading"-->
            <!--              :option="dashboardLineChart"-->
            <!--              v-loading="loading"-->
            <!--            />-->
            <div class="">
              <div class="gen-heigh custom-gen-heigh"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- End charts-->

      <!--Tables-->
      <div class="row">
        <!--        <div class="col-xl-6">-->
        <!--          <div class="gen-heigh">-->
        <!--            <social-traffic-table-->
        <!--              :tghData="testGenerationTableData"-->
        <!--            ></social-traffic-table>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="col-xl-6 h-50">-->
        <!--          <card header-classes="bg-transparent" class="test-card-h">-->
        <!--            <div slot="header" class="row align-items-center">-->
        <!--              <div class="col">-->
        <!--                <h5 class="h3 mb-0">{{ $t('dashboard.test_heals') }}</h5>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <v-chart-->
        <!--              class="chart"-->
        <!--              :autoresize="true"-->
        <!--              :option="dashboardPieChart"-->
        <!--              v-loading="loading"-->
        <!--            />-->
        <!--          </card>-->
      </div>
    </div>
    <!--End tables-->
  </div>
</template>

<script>
import StatsCard from "@/components/Cards/StatsCard";
import SocialTrafficTable from "./SocialTrafficTable";
import "echarts";
import LeftSideBar from "../../components/SidebarPlugin/LeftSideBar.vue";
import VChart from "vue-echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import rrwebPlayer from "rrweb-player";
import Chart from "chart.js";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import axios from "axios";
import moment from "moment";
import {
  TextHighlightRules,
  delayedCall,
} from "vue-json-editor/assets/jsoneditor";
import { Bottom } from "@element-plus/icons-vue";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  components: {
    VChart,
    StatsCard,
    SocialTrafficTable,
    LeftSideBar,
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
  data() {
    return {
      loadingTests: true,
      testsNum: 0,
      log: console.log,
      chartLoading: false,
      testGenerationTableData: [],
      bg: "",
      dashboardData: "",
      dashboardLineChart: {
        title: {
          text: "",
          left: "center",
          top: "center",
        },
        tooltip: {
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          data: [],
        },
        yAxis: {},
        series: [
          {
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
            type: "line",
            smooth: true,
            areaStyle: {
              color: "#4ac3db",
              opacity: 0.5,
            },
          },
          {
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
            type: "line",
            smooth: true,
            areaStyle: {
              color: "#FCA311",
              opacity: 0.5,
            },
          },
        ],
      },
      dashboardPieChart: {
        legend: {
          orient: "vertical",
          x: "left",
          y: "center",
          data: [],
        },
        title: {
          text: "Test Heals",
          left: "center",
          top: "center",
        },
        series: [
          {
            type: "pie",
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
            radius: ["40%", "70%"],
          },
        ],
      },
      name_activity: "",
      loading: false,
      resultSession: {},
      totalSession: 0,
      loadingProject: false,
      loadingSession: false,
      loadingAppMap: false,
      toShowKeyFlows: 0,
      bugFinder: 0,
      loadingLatestSession: false,
      toShowLatestKeyFlow: 0,
      toShowAllKeyFlows: {},
      projectDetail: {},
      run_name: "",
      resultProject: [],
      resultMapp: [],
      toShowLatestProjects: [],
      webPlayer: null,
      events: [],
      loadingVideo: false,
      screeshotsAll: [],
      toShowVideo: false,
      overviewPieChart: {},
      gaugeData: {},
      abortController: null,
      google_session: 0,
    };
  },
  methods: {
    async mathResults() {
      let res = await axios.get(
        `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/stats`
      );
      var failedNum = 0;
      var passedNum = 0;
      var errorStartingRunNum = 0;
      this.totalSession = res.data.response.num_sessions;
      this.toShowKeyFlows =
        res.data.response.downloaded_file &&
        res.data.response.downloaded_file.download_test_file
          ? res.data.response.downloaded_file.download_test_file.event_count
          : 0;
      this.google_session =
        res.data.response.file_upload &&
        res.data.response.file_upload.upload_session_with_googlerecording
          ? res.data.response.file_upload.upload_session_with_googlerecording
              .event_count
          : 0;
      this.downloaded_tests =
        res.data.response.downloaded_file["download_test_file"];
      this.last_10_sessions =
        res.data.response.downloaded_file["last_10_sessions"];
      // console.log("this.downloaded_tests: ", this.downloaded_tests)

      // this.bugFinder =
      if (
        res.data.response.file_upload.upload_session_with_zip_file &&
        res.data.response.file_upload.upload_session_with_wringr_file
      ) {
        this.bugFinder =
          res.data.response.file_upload.upload_session_with_zip_file
            .event_count +
          res.data.response.file_upload.upload_session_with_wringr_file
            .event_count;
      } else if (res.data.response.file_upload.upload_session_with_zip_file) {
        this.bugFinder =
          res.data.response.file_upload.upload_session_with_zip_file.event_count;
      } else if (
        res.data.response.file_upload.upload_session_with_wringr_file
      ) {
        this.bugFinder =
          res.data.response.file_upload.upload_session_with_wringr_file.event_count;
      }

      var allTests = this.last_10_sessions;

      let tgtd = [];

      allTests.map((v, i) => {
        tgtd.push({
          name: v.name,
          output_type: v.output_type,
        });
      });

      this.testGenerationTableData = tgtd;
      this.createCricleDiagram(
        res.data.response.downloaded_file &&
          res.data.response.downloaded_file.download_test_file
          ? res.data.response.downloaded_file.download_test_file
              .average_duration
          : 0
      );

      if (allTests.length > 0) {
        this.testsNum = allTests.length;
      }

      // Assuming `allTests` is an array of objects where each object has a `name` and `output_type` field
      const outputTypeFrequency = {};

      // Build the frequency histogram
      allTests.forEach((test) => {
        const outputType = test.output_type;
        if (outputTypeFrequency[outputType]) {
          outputTypeFrequency[outputType] += 1;
        } else {
          outputTypeFrequency[outputType] = 1;
        }
      });

      // If you want to convert the histogram to an array for further processing
      const outputTypeHistogram = Object.keys(outputTypeFrequency).map(
        (outputType) => {
          return {
            output_type: outputType,
            count: outputTypeFrequency[outputType],
          };
        }
      );

      // Example output for console or further processing
      console.log("Frequency histogram::");
      console.log(outputTypeHistogram);

      // var failed = Math.round((failedNum / allTests.length) * 100)
      // var passed = Math.round((passedNum / allTests.length) * 100)
      // var errorStartingRun = Math.round((errorStartingRunNum / allTests.length) * 100)
      // }else{
      // var failed = 0
      // var passed = 0
      // var errorStartingRun = 0
      // }
      let data_overviewPieChart = [];
      let data_leng = [];
      let count = 0;
      for (const dt of outputTypeHistogram) {
        data_leng.push(`${dt.output_type} ${dt.count}`);
        // passed = dt.test_count

        data_overviewPieChart.push({
          value: dt.count,
          name: `"${
            dt.output_type.toString().charAt(0).toUpperCase() +
            dt.output_type.toString().slice(1)
          }" - ${dt.count}`,
          itemStyle:
            count > 2
              ? {}
              : {
                  color:
                    count === 0
                      ? "#947BB0"
                      : count === 1
                      ? "#1F213E"
                      : count === 2
                      ? "#535C91"
                      : "",
                },
        });
        count++;
      }
      this.overviewPieChart = {
        type: "common",
        layout: {
          type: "grid",
          col: 1,
          row: 2,
          elements: [
            {
              modelId: "legend",
              col: 0,
              row: 1,
              colSpan: 4,
            },
            {
              modelId: "DAU",
              col: 0,
              row: 0,
            },
          ],
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}",
          position: "top",
        },
        region: [
          {
            id: "DAU",
          },
        ],
        legends: {
          orient: "bottom",
          id: "legend",
          regionId: ["DAU"],
          data: data_leng,
          item: {
            visible: true,
            background: {
              style: {
                fill: "transparent",
              },
            },
          },
        },
        series: [
          {
            name: "Generated Tests by Language",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            regionId: "DAU",
            data: data_overviewPieChart,
            emphasis: {
              itemStyle: {
                shadowBlur: 100,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    createCricleDiagram(value) {
      // var value = 0
      // if(this.bugFinder || this.testsNum){
      //    value = this.bugFinder / this.testsNum
      // }
      // if(value == NaN){
      //   value = 0
      // }
      this.gaugeData = {
        series: [
          {
            startAngle: 90,
            endAngle: -270,
            radius: "55%",
            type: "gauge",
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: "#D5D5DE",
                color: [[1, "#D5D5DE"]],
              },
            },
            axisLine: {
              lineStyle: {
                width: 5,
                color: [[1, "#D5D5DE"]],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 25,
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 32,
              color: "#3F3E6E",
              borderColor: "#3F3E6E",
              borderRadius: 0,
              borderWidth: 0,
              formatter: function (value) {
                if (value >= 60) {
                  // Convert to minutes and seconds
                  const minutes = Math.floor(value / 60);
                  const seconds = Math.round(value % 60); // 1 decimal place for seconds
                  return `${minutes}m ${seconds
                    .toFixed(1)
                    .replace(/\.0$/, "")}s`;
                } else {
                  // Round to 1 decimal place and remove trailing zeros
                  const roundedValue = Math.round(value * 10) / 10;
                  return `${roundedValue.toFixed(1).replace(/\.0$/, "")}s`;
                }
              },
              offsetCenter: ["0%", "5%"],
            },
            data: [
              {
                value: value,
              },
            ],
          },
        ],
      };
    },

    getResultSessions() {
      if (this.resultSession != 0) {
        this.toShowVideo = true;
      } else {
        console.log("No Video");
      }
    },
    async getData() {
      // try {
      //   this.loading = true;
      //   this.loadingSession = true
      //   this.loadingAppMap = true
      //   this.loadingLatestSession = true
      //   await axios
      //     .get(`${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/stats`)
      //     .then((res) => {
      //       if (res && res.message === "Request failed with status code 402") {
      //         localStorage.removeItem("vue-authenticate.access_token");
      //         localStorage.removeItem("testgold.aioToken");
      //         localStorage.setItem(
      //           "login--message",
      //           "User trial period has ended. Please contact support@testgold.dev."
      //         );
      //         window.location.reload();
      //       } else {
      //         this.dashboardData = res.data.response;
      //         if (Object.keys(res.data.response).length === 0) {
      //           this.dashboardPieChart.title.text = "No Data Available";
      //         }
      //         let dpc = [];

      //         dpc.push(
      //           {
      //             value: res.data.response.num_heals,
      //             name: this.$t("dashboard.auto_healed") + ` ${res.data.response.num_healed}`,
      //             itemStyle: {
      //               color: "#4ac3db",
      //             },
      //           },
      //           {
      //             value: res.data.response.num_trained,
      //             name: this.$t("dashboard.suggested") + ` ${res.data.response.num_trained}`,
      //             itemStyle: {
      //               color: "#FCA311",
      //             },
      //           }
      //         );

      //         this.dashboardPieChart.series[0].data = [...dpc].reverse();
      //         this.dashboardPieChart.legend.data.push(
      //           this.$t("dashboard.auto_healed") + ` ${res.data.response.num_healed}`,
      //           this.$t("dashboard.suggested") + ` ${res.data.response.num_trained}`
      //         );
      //         let items = Object.values(res.data.response.test_runs);
      //         console.log(items)
      //         let tgtd = [];
      //         let dlcZeroData = [];
      //         let dlcOneData = [];
      //         let dlcxAxisData = [];
      //         items.map((v, i) => {
      //           tgtd.push({
      //             name: v.name,
      //             request_id: v.request_id,
      //             status: v.status,
      //           });

      //           dlcZeroData.push(v.healed_count);
      //           dlcOneData.push(v.trained_count);
      //           let sec = moment(v.accepted_at);
      //           const time_display = sec.format("HH:mm");
      //           const day_display = sec.format("YYYY-MM-DD");
      //           dlcxAxisData.push(`${day_display} \n ${time_display}`);
      //         });

      //         this.testGenerationTableData = tgtd;
      //         this.loadingTests = false
      //         var reversedlcZeroData = [...dlcZeroData].reverse();
      //         var reversedlcOneData = [...dlcOneData].reverse();
      //         var reversedlcxAxisData = [...dlcxAxisData].reverse();
      //         // this.bugFinder = this.dashboardData.num_healed + this.dashboardData.num_suggested
      //         this.dashboardLineChart.series[0].data = reversedlcZeroData;
      //         this.dashboardLineChart.series[1].data = reversedlcOneData;
      //         this.dashboardLineChart.xAxis.data = reversedlcxAxisData;
      //         return res.data;
      //       }
      //     })
      //     .catch((e) => {
      //       this.$notify({
      //         type: "danger",
      //         message: `An error has occured ${e.response.message}`,
      //       });
      //     });
      // } catch (e) {
      //   this.$notify({
      //     type: "danger",
      //     message: `An error has occured ${e.response.message}`,
      //   });
      // } finally {
      //   this.loading = false;
      //   this.loadingTests = false
      // }
      this.loadingTests = false;
    },
    getSlicName(name) {
      let tName = name.split(" ");
      if (tName.length > 1) {
        return tName[0][0] + tName[1][0];
      } else {
        return name[0] + name[1];
      }
    },
    showComponent(id) {
      if (id) {
        this.$router.push({
          name: "List Sessions",
          params: {
            id: id,
          },
        });
      }
    },
    formatDate(a) {
      return moment(a).format("MMM DD YYYY, h:mm:ss a");
    },
    showDetailsSession() {
      const url =
        this.resultSession[0].person.properties.$initial_current_url.replace(
          /https:\/\//g,
          ""
        );
      this.run_name =
        url +
        " " +
        this.resultSession[0].person.properties.$initial_browser +
        " " +
        this.resultSession[0].person.properties.$geoip_country_name;
      this.$router.push({
        name: "Details Session",
        params: {
          id: "449",
          session_id: this.resultSession[0].id,
          run_name: this.run_name,
          start_time: this.resultSession[0].start_time,
          recording_duration: this.resultSession[0].recording_duration,
          name: "Bazaar App",
        },
      });
    },
    async getProject() {
      this.loadingProject = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/projects/v1`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        let isRepeat = "";
        const res = await axios.get(url);
        if (
          (res.data && res.data.status == "success") ||
          res.data.status == 200
        ) {
          this.name_activity = "Last Activity";
          this.resultProject = Object.values(res.data.response);
          this.resultProject.map((item) => {
            if (localStorage.getItem("latest_projects")) {
              this.toShowLatestProjects = this.toShowLatestProjects.filter(
                (project) => project.name != "Example"
              );
              let temp = JSON.parse(localStorage.getItem("latest_projects"));
              temp.map((itemTemp) => {
                if (itemTemp.name === item.name) {
                  if (isRepeat !== itemTemp.name) {
                    isRepeat = itemTemp.name;
                    console.log(isRepeat);
                    this.toShowLatestProjects.push(item);
                  }
                }
              });
            } else {
              this.name_activity = "Example Activity";
              let example = {
                name: "Example",
                date: "2023-11-29T12:06:06.434Z",
                projectId: 0,
              };
              this.toShowLatestProjects.push(example);
              example.map((itemTemp) => {
                if (itemTemp.name === item.name) {
                  if (isRepeat !== itemTemp.name) {
                    isRepeat = itemTemp.name;
                    console.log(isRepeat);
                    this.toShowLatestProjects.push(item);
                  }
                }
              });
            }
          });
          for (let i = 0; i < this.toShowLatestProjects.length; i++) {
            // const resp = await axios.get(process.env.VUE_APP_API_URL_PREFIX + `/pageviews/appmap?project_id=${this.toShowLatestProjects[i]['projectId']}&date_from=2024-02-3`);
            // let appmapId = resp.data.appmaps[0]['appmap_id']
            // const url = await axios.get(`${process.env.VUE_APP_API_URL_PREFIX}/pageviews/appmap?project_id=${this.toShowLatestProjects[i]['projectId']}&app_map_id=${appmapId}`)
            let screenshot = url.data.summary_graph.nodes[0]["screenshot"];
            this.screeshotsAll.push(screenshot);
            await this.$nextTick(() => {
              let elementImage = document.querySelectorAll(".elt--banner_img");
              console.log(elementImage);
              if (this.screeshotsAll[i] != null) {
                elementImage[
                  i
                ].style.backgroundImage = `url(data:image/png;base64,${this.screeshotsAll[i]})`;
                elementImage[i].style.backgroundSize = "100% 100%";
                elementImage[i].style.backgroundRepeat = "no-repeat";
                elementImage[i].style.zIndex = 999;
                elementImage[i].style.backgroundPosition = "center";
              } else {
                elementImage[i].style.backgroundColor = "blue";
              }
            });
          }
        } else {
          this.resultProject = [];
        }
      } catch (error) {
        // this.$notify({
        //   type: "danger",
        //   duration: 5000,
        //   message: "an error has occurred ",
        // });
      }
    },
    secondsToRealTime(sec) {
      sec = Number(sec);
      let h = Math.floor(sec / 3600);
      let m = Math.floor((sec % 3600) / 60);
      let s = Math.floor((sec % 3600) % 60);
      let result = "";
      let numPreviousValues = 0;
      if (h > 0) {
        result +=
          h +
          (h === 1
            ? this.$t("dashboard.singular_hour")
            : this.$t("dashboard.plural_hour"));
        numPreviousValues++;
      }
      if (m > 0) {
        if (numPreviousValues > 0) {
          result += ", ";
        }
        result +=
          m +
          (m === 1
            ? this.$t("dashboard.singular_minute")
            : this.$t("dashboard.plural_minute"));
        numPreviousValues++;
      }

      if (s > 0 && (m === 0 || h === 0)) {
        if (numPreviousValues > 0) {
          result += ", ";
        }
        result +=
          s +
          (s === 1
            ? this.$t("dashboard.singular_second")
            : this.$t("dashboard.plural_second"));
        numPreviousValues++;
      }
      if (sec === 0) result = "0s";
      return result;
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      var minutes = Math.floor(secs / 60);
      var dis_sec = Math.floor(secs % 60);
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      // return `${this.pad(hours)}h ${this.pad(minutes)}m ${this.pad(secs)}s`;
      return result;
      // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
    },

    startAndEndDate(dateTime, numberOfDay = 4, format = "YYYY-MM-DD") {
      const startDate = moment(dateTime);
      const endDate = moment(dateTime).add(numberOfDay, "days");

      return [startDate.format(format), endDate.format(format)];
    },
    getTimeSaved(a, b, c) {
      const totalHealSecs = a * 3600;
      const totalSuggestedSecs = b * 300;
      let total = totalHealSecs + totalSuggestedSecs;
      if (total) return this.hhmmss(total);
      else return "N/A";
    },
    secondsToRealTimeEvent(start, end) {
      return (Date.parse(end) - Date.parse(start)) / 1000;
    },
    async getSessions() {
      // const [startAt, endAt] = this.startAndEndDate(new Date())
      // this.loadingSession = true;
      // var controller = new AbortController()
      // console.log(this.resultProject)
      // try {
      //   for (const projectId of this.resultProject) {
      //     console.log(projectId)
      //     const response = await axios.get(`${process.env.VUE_APP_API_URL_PREFIX}/pageviews/sessions?after=${startAt}&before=${endAt}&project_id=${projectId.projectId}`, { signal: controller.signal });
      //     console.log(`RES : ${response.data.response.length}`)
      //     if (response && response.data && response.data.status === "success" && response.data.response !== null) {
      //       this.totalSession += response.data.response.length;
      //       if (this.$route.name !== "Dashboard") {
      //         controller.abort()
      //       }
      //     } else {
      //       this.resultSession = [];
      //       this.totalSession += 0;
      //     }
      //   }
      // } catch (error) {
      //   console.error(error);
      // } finally {
      //   this.loadingSession = false;
      // }
      // var res = await axios.get(`${process.env.VUE_APP_API_URL_PREFIX}/pageviews/dbstats/v1`)
      // this.totalSession = res.data.response['num_sessions']
    },
    async getAppMap() {
      this.loadingAppMap = true;
      // axios.defaults.validateStatus = (status) => {
      //   return status === 400 || (status >= 200 && status < 500)
      // }
      // const mainProject = await axios.get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/keyflow?" + `project_id=f2524871dab06b15f298efd3b6e40d52`)
      // console.log(mainProject)
      // if (mainProject.status === 200) {
      //   this.toShowAllKeyFlows = mainProject.data.keyflows
      //   this.toShowLatestKeyFlow = mainProject.data.keyflows.length - 1
      //   console.log("lates", this.toShowLatestKeyFlow)
      // }
      //  const res = await axios.get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/keyflow?total=yes")
      //  console.log(res)
      //   //   })
      //   this.toShowKeyFlows = res.data.keyflows
      //   this.loadingAppMap = false
      //   this.loadingSession = false;
    },
    async getVideoSession() {
      const rrweb = document.getElementById("rrweb");
      this.webPlayer = new rrwebPlayer({
        target: rrweb,
        props: {
          events: this.events,
          width: 450,
          height: 350,
          autoPlay: false,
          skipInactive: false,
        },
      });
      await this.deleteSpan();
    },
    async getSnapshots() {
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 300);
      };
      await axios
        .get(
          process.env.VUE_APP_API_URL_PREFIX +
            `/pageviews/snapshots/v2?project_id=f2524871dab06b15f298efd3b6e40d52&session_id=${this.resultSession[0].id}`
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              // this.$notify({
              //   type: "danger",
              //   message: "the recovery of the video failed ",
              // });
              this.events = [];
            } else if (response.data.status === "success") {
              this.events = [];
              // let index =  Object.entries(response.data.response.result.snapshot_data_by_window_id)[0]
              // this.events = response.data.response.result.snapshot_data_by_window_id[index[0]]
              this.events = response.data.response.snapshots;
              this.loadingVideo = false;
              this.getVideoSession();
            } else if (response.data.status === "failure") {
              // this.$notify({
              //   type: "danger",
              //   message: "the recovery of the video failed ",
              // });
              this.events = [];
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$notify({
          //   type: "danger",
          //   message: "an error occurred while retrieving data ",
          // });
        });
    },
    async getLatestSession() {
      this.loadingLatestSession = true;
      let isLoaded = false;
      await axios
        .get(
          process.env.VUE_APP_API_URL_PREFIX +
            `/pageviews/sessions?&project_id=f2524871dab06b15f298efd3b6e40d52&limit=1`
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              // this.$notify({
              //   type: "danger",
              //   message: "an error has occured",
              // })
              this.resultSession = [];
              this.totalSession += 0;
            } else if (
              response.data.status === "success" &&
              response.data.response !== null
            ) {
              this.resultSession = response.data.response;
              console.log("latest session", this.resultSession[0]);
              isLoaded = true;

              this.totalSession += response.data.response.length;
            } else {
              this.resultSession = [];
              this.totalSession += 0;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      if (isLoaded) {
        await this.getSnapshots();
      }
      this.loadingLatestSession = false;
    },
    async deleteSpan() {
      let buttons = document.querySelector(".rr-controller__btns");
      let div = buttons.querySelector("div");
      let span = div.querySelector("span");
      span.style.display = "none";
      let buttonShowDet = document.getElementById("show_det_btn");
      console.log(buttonShowDet);
      buttons.appendChild(buttonShowDet);
    },
  },

  async created() {
    this.$store.dispatch("loadImage");
    this.abortController = new AbortController();
    await this.mathResults();
    await this.getData();
    await this.getProject();
    // await this.getLatestSession()
    await this.getAppMap();
    await this.getSessions();
    this.getResultSessions();
  },
};
</script>

<style lang="scss">
$pColor: #525f7f;
$color: #5e72e4;

.header-body {
  width: 100%;
}

.custom-gen-heigh {
  display: flex;
  flex-direction: column;
}

.header-body .row {
  justify-content: space-between;
}

.header .container-fluid {
  justify-content: center;
}

.container-fluid {
  padding: 10px 0 10px 0;
}

.custuom_row {
  display: flex;
  flex-direction: row;
}

.page_name_section {
  display: flex;
  align-items: center;
  gap: 0px;
  flex-direction: row;
  max-height: 182px;
}

.page_name {
  color: var(--main-blue-color);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.col-xl-7 {
  border-top-left-radius: 10px;
  padding-left: 15px;
}

.el-button {
  font-size: 16px;
}

/* .switch{
  margin-left: 5px;
  width:45px;
  margin-right: 0 !important;
} */
.svelte-9brlez {
  margin-bottom: 0 !important;
}

.custom-btn--details {
  border-radius: 20px;
  color: rgb(73, 80, 246);
  border-color: rgb(73, 80, 246);
  font-size: 16px !important;
  transition: 0.3s;
}

.custom-btn--details:hover {
  border-color: rgb(73, 80, 246);
  background-color: white;
  color: rgb(73, 80, 246);
  transform: translateX(10px);
}

.text-nowrap {
  font-size: 16px;
  color: black;
  font-weight: 400;
}

.custom-cards-dash {
  border-radius: 16px;
  width: 240px;
  height: 128px;
  background-color: #1f213e;
}

.card-stats:hover {
  border-radius: 16px;
}

.custom-cards-dash .card-body {
  height: 130px;
}

.custom-cards-dash .card-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.custom-cards-dash .card-subtitle {
  color: white !important;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.custom-cards-dash .row {
  height: 100%;
}

.text-muted {
  color: white !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;
}

.container-content {
  padding: 30px;
  margin: 10px;
  width: 80%;
  // display: grid;
  grid-template-columns: repeat();
  overflow-y: scroll;
}

.eff--page {
  width: 100%;
}

.chart {
  width: 100%;
}

.icon-shape {
  background: #e6e6e6;
}

.temp {
  padding: 10px;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container-mainstats {
  display: flex;
  width: 100%;
}

.test-card-h {
  height: 500px;
}

.gen-heigh {
  height: 100%;
}

.test-heal {
  height: 100%;
}

.b {
  border: 1px solid red;
}

.custom-chart-gauge {
  width: 400px;
  height: 400px;
}

@media screen and (max-width: 1182px) {
  .r-3-cards {
    margin-top: 2rem;
  }
}

.custom-chart-gauge-pie {
  position: relative;
  width: 500px;
  height: 256px;
  background-color: #e0e0e0;
  border-radius: 16px;
}
.custom-chart .chart {
  height: 400px !important;
  width: 350px;
}

@media screen and(max-width: 1450px) {
  .custom-chart-gauge {
    width: 300px;
  }
  .custom-chart-gauge-pie {
    width: 350px;
  }
  .custom-chart .chart {
    width: 300px;
    height: 300px;
  }
}

.only-body {
  display: flex;
  align-items: center;
  margin: auto;
  padding: 15px 0px 20px 0;

  .card-body {
    .row {
      align-items: center;

      .card-details {
        margin-top: 3px;

        .card-subtitle {
          font-size: 1rem;
          font-weight: 700 !important;
        }
      }
    }
  }
}

.custom-btn--details {
  font-size: 10px;
  padding: 9px 9px;
}

.ctb--body {
  display: flex;
  gap: 5px;
  justify-items: center;
  flex-wrap: wrap;

  h2 {
    margin: 0 auto;
  }

  // .ctb--body-left{
  // display: grid;
  // grid-template-columns: repeat(2,1fr);
  // gap: 5px;
  // flex-wrap: wrap;
  // }

  .custom-ctbb--row {
    width: 100% !important;
  }

  .ctbb--row {
    // width: 100% !important;
    margin: 1%;
    cursor: pointer;
    overflow: hidden;
    background: #dbdbdb;
    border-radius: 0.25rem;
    position: relative;

    &.ctbb--processing {
      .ctbb--inner {
        filter: blur(2px);
        display: none;
      }
    }

    .ctbb--inner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .elt--banner {
        position: relative;

        .elt--banner_img {
          display: inline-block;
          width: 100%;
          text-align: center;
          background-color: rgba(94, 114, 228, 0.3);
        }

        .elt--banner_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: none;
        }

        .elt--banner_overlay_status {
          position: absolute;
          top: 10px;
          right: 10px;
          max-width: 200px;
          width: auto;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--banner_overlay_duration,
        .elt--banner_overlay_created {
          position: absolute;
          bottom: -10px;
          font-size: 0.7rem;
          font-weight: 700;
          color: #fff;
          background: rgba($color, 0.85);
          padding: 0.25rem;
          border-radius: 0.3rem;
        }

        .elt--banner_overlay_created {
          left: 10px;
        }

        .elt--banner_overlay_duration {
          right: 10px;
        }

        &.no--img {
          display: flex;
          align-items: center;
          height: 110%;
        }
      }

      .elt--content {
        padding: 0.6rem;
        margin-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .test-desc {
          font-size: 0.8rem;
          color: rgba($pColor, 0.75);
          font-style: italic;
        }

        .elt--value {
          font-weight: 700;
          font-size: 0.85rem;
          margin-right: 12px;
        }

        .el-divider {
          margin: 8px 0;
        }

        .el--footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border: solid red;
        }
      }
    }

    .ctbb--over {
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
      border-radius: 0.25rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0.5rem;

      img {
        display: inline-block;
        width: 100%;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 0.25rem;
        margin: auto;
        border: solid 3px rgba(#108050, 0.7);
      }

      .s--icon {
        font-size: 3rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 600;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .s--txt {
        color: #108050;
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
  }
}

.elt--banner_name {
  height: 150px;
  font-size: 7em;
  text-transform: uppercase;
  font-weight: bold;
  color: #5f72e4;
}

.el-table__body-wrapper {
  height: 400px;
}

.el-table__header {
  height: 50px;
}

.el-table .el-table__header-wrapper thead th .cell {
  text-transform: capitalize;
  color: #efefef;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.social-card-h .table-responsive,
.card .social-card-h {
  border-radius: 10px 10px 0 0;
  max-height: 450px;
}

#pieChart {
  width: 200px !important;
  height: 200px !important;
}

.social-card-h {
  border-radius: 40px;
  height: 550px;
}

.elipsis--text {
  color: #6e6e6e;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tbl--body {
  width: 100%;
  display: block;
  flex-direction: column;
  justify-content: start;

  .tblb--row {
    width: 100%;
    background: #e0e0e0;
    box-shadow: 0 0 0rem 0 rgba(136, 152, 170, 0.15);
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 30px -5px rgba(#111, 0.25);
      transition: all 0.25s ease-in;
    }

    &.tblb--processing {
      min-height: 350px;

      .tblb--inner {
        filter: blur(1px);
      }
    }

    .tblb--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .elt-row {
        padding: 1.4rem 0.8rem;
        font-size: 0.81rem;
        display: inline-block;

        &.flex--elt {
          display: flex;
        }

        .el-alert {
          display: inline;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--value {
          font-weight: 700;
        }

        .elt--txt {
          font-size: 0.7rem;
        }

        .elt--banner_img {
          border-radius: 0.35rem;
          overflow: hidden;
          width: 100%;

          img {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    .tblb--over {
      border-bottom: 1px solid #cccccc;
      gap: 10px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #e0e0e0 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999;

      .tblbo--img {
        height: 100%;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;

        img {
          height: 100%;
        }
      }

      .s--icon {
        font-size: 2.5rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 900;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .tblo--step {
        .s--txt {
          color: #108050;
          font-size: 0.8rem;
          font-weight: 800;
        }
      }
    }
  }
}
.container-nodata-test {
  height: 100%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 300px;
  border-radius: 10px;
}
.container-nodata-test h3 {
  font-size: 24px;
  font-weight: 600;
}
.diagrams-container {
  display: grid;
  width: 45%;
  margin-left: 25px;
  grid-template-columns: repeat(1, 1fr);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 265px);
}

.card-stats {
  margin-bottom: 10px;
  width: 240px;
  height: 108px;
}

@media screen and (max-width: 1300px) {
  .diagrams-container {
    margin-left: 15px;
  }
  .stats-grid {
    grid-template-columns: repeat(2, 250px);
  }
}

@media screen and (max-height: 750px) {
  .social-card-h {
    max-height: 450px !important;
  }
}

@media screen and (max-height: 650px) {
  .social-card-h {
    max-height: 350px !important;
  }
}

.custom-chart-gauge:nth-child(1n) .chart {
  height: 350px;
}
</style>
<style scoped>
.elt-row {
  text-align: left;
}
</style>
